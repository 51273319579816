import React from 'react';
import Layout from '../components/layout';
import Intro from '../components/sections/careers/intro';
import SEO from '../components/seo';

const CareersPage = () => {
	return (
		<Layout>
			<SEO title="Karriere" />

			<Intro />
		</Layout>
	);
};
export default CareersPage;
