import React from 'react';
import { makeStyles } from '@material-ui/core';
import { StaticImage } from 'gatsby-plugin-image';
import content from '../../../content/careers/intro.json';
import Colors from '../../../styles/colors';
import FiraSans from '../../../fonts/Fira_Sans/firaSans';
import Dimensions from '../../../styles/dimensions';

const Intro = () => {
	const classes = useClasses();

	return (
		<section className={classes.section}>
			<StaticImage
				src="../../../images/careers/bannerCareer.svg"
				alt=""
				layout="fullWidth"
				loading="eager"
				placeholder="blurred"
				objectFit="cover"
				objectPosition="center center"
				className={classes.banner}
			/>
			<StaticImage
				src="../../../images/careers/banner.jpeg"
				alt=""
				layout="constrained"
				loading="eager"
				placeholder="blurred"
				objectFit="cover"
				objectPosition="center center"
				className={classes.mobileBanner}
			/>
			<div className={classes.contentContainer}>
				<h1 className={classes.header}>
					{content.titlePart1} <span className={classes.headerPart2}>{content.titlePart2}</span>
				</h1>

				<p className={classes.paragraph}>{content.description}</p>

				<div className={classes.bulletPoints}>
					{content.paragraphs.map((item, key) => (
						<p key={key} className={classes.bulletPoint}>
							{item}
						</p>
					))}
				</div>

				<p className={classes.paragraph}>{content.applyNow}</p>

				<div className={classes.linkWrapper}>
					<a href="mailto:schwerin@gymedge.de" className={classes.applyButton}>
						{content.applyNowButton}
					</a>
				</div>
			</div>
		</section>
	);
};

export default Intro;

const useClasses = makeStyles({
	section: {
		backgroundColor: Colors.white,
		paddingBottom: '80px',
	},
	banner: {
		'@media (max-width: 599px)': {
			display: 'none !important',
		},
	},
	mobileBanner: {
		height: 'calc(100vh - 6.25em)',
		'@media (min-width: 600px)': {
			display: 'none !important',
		},
	},

	contentContainer: {
		paddingLeft: '16px',
		paddingRight: '16px',
	},
	header: {
		color: Colors.textBlack,
		textAlign: 'center',
		marginTop: '120px',
		marginBottom: '48px',
		'@media (max-width: 599px)': {
			fontSize: Dimensions.smallTitle.fontSize,
			lineHeight: Dimensions.smallTitle.lineHeight,
		},
	},
	headerPart2: {
		color: Colors.limeGreen,
	},
	paragraph: {
		textAlign: 'center',
		color: Colors.textBlack,
		whiteSpace: 'pre-line',
		fontFamily: FiraSans.book,
		fontSize: Dimensions.regularText.fontSize,
		lineHeight: Dimensions.regularText.lineHeight,
		margin: '48px 0',
		'&:last-of-type': {
			marginBottom: 0,
		},
		'@media (max-width: 599px)': {
			fontSize: Dimensions.smallText.fontSize,
			lineHeight: Dimensions.smallText.lineHeight,
		},
	},
	bulletPoints: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '100%',
		margin: '10px 0 20px',
	},
	bulletPoint: {
		textAlign: 'center',
		color: Colors.textBlack,
		fontFamily: FiraSans.book,
		fontSize: Dimensions.regularText.fontSize,
		lineHeight: Dimensions.regularText.lineHeight,
		margin: '8px 0',
		'@media (max-width: 599px)': {
			fontSize: Dimensions.smallText.fontSize,
			lineHeight: Dimensions.smallText.lineHeight,
		},
	},
	linkWrapper: {
		width: '100%',
		marginTop: '1em',
		display: 'flex',
		justifyContent: 'center',
	},
	applyButton: {
		backgroundColor: Colors.limeGreen,
		color: Colors.white,
		alignSelf: 'center',
		padding: '12px 30px',
		border: 'none',
		borderRadius: '4px',
		fontSize: '16px',
		fontWeight: 'bold',
		cursor: 'pointer',
		marginTop: '10px',
		marginBottom: '30px',
		fontFamily: FiraSans.medium,
		'&:hover': {
			backgroundColor: Colors.darkLimeGreen,
		},
	},
});
